/*!

                      llllllllllllllll             t
                          llllllllllll            tt
                            llllllllll           ttt
                            llllllllll        tttttt
                            llllllllll    tttttttttt
                            lllllllllltttttttttttttttttttt
     aaaaaaaaaaaaaaa        llllllllll   ttttttttttt
  aaaaa      aaaaaaaaaa     llllllllll   ttttttttttt
 aaaaaaaaa     aaaaaaaaa    llllllllll   ttttttttttt
aaaaaaaaaaa    aaaaaaaaaa   llllllllll   ttttttttttt
aaaaaaaaaaa    aaaaaaaaaa   llllllllll   ttttttttttt
 aaaaaaaa     aaaaaaaaaaa   llllllllll   ttttttttttt
            aa aaaaaaaaaa   llllllllll   ttttttttttt
       aaaaa   aaaaaaaaaa   llllllllll   ttttttttttt
   aaaaaaaa    aaaaaaaaaa   llllllllll   ttttttttttt
 aaaaaaaaaa    aaaaaaaaaa   llllllllll   ttttttttttt
aaaaaaaaaa     aaaaaaaaaa   llllllllll   ttttttttttt      t
aaaaaaaaaa     aaaaaaaaaa   llllllllll   ttttttttttt     tt
aaaaaaaaaa     aaaaaaaaaa   llllllllll   ttttttttttt     tt
aaaaaaaaaaa   aaaaaaaaaaa   llllllllll    ttttttttttt    tt
aaaaaaaaaaa aa aaaaaaaaaa   lllllllll       tttttttttt  tt
  aaaaaaaaaaa   aaaaaaaaaa  llllllll         tttttttttttt
     aaaaa         aaaaaaaaallllll              tttttt
*/
@font-face {
  font-family: 'aileronbold';
  src: url("../fonts/aileron-bold-webfont.eot");
  src: url("../fonts/aileron-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-bold-webfont.woff2") format("woff2"), url("../fonts/aileron-bold-webfont.woff") format("woff"), url("../fonts/aileron-bold-webfont.ttf") format("truetype"), url("../fonts/aileron-bold-webfont.svg#aileronbold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronheavy';
  src: url("../fonts/aileron-heavy-webfont.eot");
  src: url("../fonts/aileron-heavy-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-heavy-webfont.woff2") format("woff2"), url("../fonts/aileron-heavy-webfont.woff") format("woff"), url("../fonts/aileron-heavy-webfont.ttf") format("truetype"), url("../fonts/aileron-heavy-webfont.svg#aileronheavy") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronlight';
  src: url("../fonts/aileron-light-webfont.eot");
  src: url("../fonts/aileron-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-light-webfont.woff2") format("woff2"), url("../fonts/aileron-light-webfont.woff") format("woff"), url("../fonts/aileron-light-webfont.ttf") format("truetype"), url("../fonts/aileron-light-webfont.svg#aileronlight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronregular';
  src: url("../fonts/aileron-regular-webfont.eot");
  src: url("../fonts/aileron-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-regular-webfont.woff2") format("woff2"), url("../fonts/aileron-regular-webfont.woff") format("woff"), url("../fonts/aileron-regular-webfont.ttf") format("truetype"), url("../fonts/aileron-regular-webfont.svg#aileronregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronbold_italic';
  src: url("../fonts/aileron-bolditalic-webfont.eot");
  src: url("../fonts/aileron-bolditalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-bolditalic-webfont.woff2") format("woff2"), url("../fonts/aileron-bolditalic-webfont.woff") format("woff"), url("../fonts/aileron-bolditalic-webfont.ttf") format("truetype"), url("../fonts/aileron-bolditalic-webfont.svg#aileronbold_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronitalic';
  src: url("../fonts/aileron-italic-webfont.eot");
  src: url("../fonts/aileron-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-italic-webfont.woff2") format("woff2"), url("../fonts/aileron-italic-webfont.woff") format("woff"), url("../fonts/aileron-italic-webfont.ttf") format("truetype"), url("../fonts/aileron-italic-webfont.svg#aileronitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aileronlight_italic';
  src: url("../fonts/aileron-lightitalic-webfont.eot");
  src: url("../fonts/aileron-lightitalic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/aileron-lightitalic-webfont.woff2") format("woff2"), url("../fonts/aileron-lightitalic-webfont.woff") format("woff"), url("../fonts/aileron-lightitalic-webfont.ttf") format("truetype"), url("../fonts/aileron-lightitalic-webfont.svg#aileronlight_italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'icons';
  src: url("../fonts/icons.eot?-q38yzg");
  src: url("../fonts/icons.eot?#iefix-q38yzg") format("embedded-opentype"), url("../fonts/icons.ttf?-q38yzg") format("truetype"), url("../fonts/icons.woff?-q38yzg") format("woff"), url("../fonts/icons.svg?-q38yzg#icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-note1:before {
  content: "\e609"; }

.icon-note2:before {
  content: "\e60a"; }

.icon-basket:before {
  content: "\e606"; }

.icon-star:before {
  content: "\e607"; }

.icon-list:before {
  content: "\e600"; }

.icon-grid:before {
  content: "\e601"; }

.icon-search:before {
  content: "\e602"; }

.icon-facebook:before {
  content: "\e603"; }

.icon-twitter:before {
  content: "\e604"; }

.icon-home:before {
  content: "\e605"; }

.icon-menu:before {
  content: "\e608"; }

/*
*
*  Reset CSS
*  adapted from http://meyerweb.com/eric/tools/css/reset/index.html
*
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }
  q:before, q:after, blockquote:before, blockquote:after {
    content: "";
    content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

/*
*
*  Simple fluid media
*
*/
figure {
  position: relative;
  border: 1px solid black; }

figure img, figure object, figure embed, figure video {
  display: block;
  max-width: 100%; }

.video-block iframe {
  display: block;
  max-width: 100%; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

strong {
  font-weight: normal; }

/* Default input apperance */
input, textarea {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  -webkit-appearance: none; }

input[type=checkbox] {
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #777;
  margin: 0 0.4em;
  vertical-align: -0.2em;
  transition: all 0.3s ease-in-out; }
  input[type=checkbox]:checked {
    background-color: #17A1D3;
    border-color: #17A1D3; }

input[type=radio] {
  width: 1.4em;
  height: 1.4em;
  border: 1px solid #777;
  margin: 0 0.4em;
  vertical-align: -0.2em;
  transition: all 0.3s ease-in-out;
  border-radius: 50%; }
  input[type=radio]:checked {
    background-color: #17A1D3;
    border-color: #17A1D3; }

a[href^=tel] {
  color: inherit;
  text-decoration: none; }

/**********************************************************************

Base Styles

**********************************************************************/
html {
  font-size: 18px;
  font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  html *, html *:before, html *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  html * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; }
    html *:focus {
      outline: none; }

body {
  font-size: 1em;
  line-height: 1.3333333333em;
  color: #545454; }

.table {
  display: table;
  width: 100%;
  height: 100%;
  vertical-align: middle; }
  .table .vmiddle, .table .center {
    display: table-cell;
    vertical-align: middle; }

/**********************************************************************

Elements

**********************************************************************/
svg {
  transform-origin: 50% 50%; }
  svg g {
    transform-origin: 50% 50%; }
  svg path {
    transform-origin: 50% 50%; }
  svg polygon {
    transform-origin: 50% 50%; }

svg.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: visible; }

.intro-row {
  width: 100%;
  float: left;
  padding: 0 6.6666666667em;
  text-align: center; }

button {
  cursor: pointer; }

button.back {
  float: left;
  border: none;
  background: 0 0;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #00688D;
  font-size: .7777777778em; }

.button.blue {
  display: inline-block;
  color: #fff;
  font-size: 0.7777777778em;
  line-height: 1em;
  padding: 0.8em 1.5em;
  margin: 1.5em 0 2.5em;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-transform: uppercase;
  background: url("../images/readmore-bg.svg") center center no-repeat;
  background-size: 100% 100%; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px; }
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold; }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px; }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; }
  .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none; }

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text; }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%; }
  .select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left; }
  .select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 10px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #333; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; }

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role=group] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd; }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0; }
  .select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em; }
    .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
      margin-left: -2em;
      padding-left: 3em; }
      .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
        margin-left: -3em;
        padding-left: 4em; }
        .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
          margin-left: -4em;
          padding-left: 5em; }
          .select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
            margin-left: -5em;
            padding-left: 6em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f6f6f6;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='white', endColorstr='#eeeeee', GradientType=0); }
  .select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px; }
  .select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px; }
  .select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999; }
  .select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0); }
    .select2-container--classic .select2-selection--single .select2-selection__arrow b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; }

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }
  .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none; }
    .select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='white', endColorstr='#eeeeee', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='white', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }
  .select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb; }
  .select2-container--classic .select2-selection--multiple .select2-selection__rendered {
    list-style: none;
    margin: 0;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px; }
  .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px; }
    .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
      color: #555; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role=group] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey; }

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.3333333333em; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0.4444444444em;
  padding-right: 1.6666666667em; }

.select2-container .select2-selection--single {
  height: 2.3333333333em;
  font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.2222222222em;
  position: absolute;
  top: 0.0555555556em;
  right: 0.0555555556em;
  width: 1.6666666667em; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #6ACBEA; }

.select2-results__option {
  padding: 0.4em;
  font-size: 0.7em;
  line-height: 1.5em; }

.select2-dropdown {
  top: 0; }

p.return-to-shop a.button.blue {
  text-decoration: none;
  font-size: 1.2em; }

.woocommerce-error {
  list-style: none !important;
  margin: 0 !important; }

.woocommerce-error li, .added-message {
  list-style: none !important;
  padding: 0 1.1111111111em;
  font-size: 1.04em;
  color: #FF8000;
  font-family: "aileronbold",Arial,"Helvetica Neue",Helvetica,sans-serif;
  margin: 0 0 1em !important; }
  .archive .woocommerce-error li, .archive .added-message {
    padding: 0 0; }

.woocommerce-error a, .added-message a {
  font-family: "aileronheavy",Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #e67300; }

/**********************************************************************

Typography

**********************************************************************/
/**********************************************************************

Gravity Forms

**********************************************************************/
p.form-row {
  width: 100%;
  float: left; }
  p.form-row.form-row-first, p.form-row.form-row-last {
    width: 50%;
    padding-right: 1.715em; }
  p.form-row label {
    color: #179FD2;
    margin-bottom: .6em;
    display: inline-block; }
  p.form-row input[type=password], p.form-row input[type=text], p.form-row input[type=email], p.form-row input[type=tel] {
    width: 100%;
    border: 1px solid #179FD2;
    color: #17A0D2;
    border-radius: 5px;
    line-height: 2.3em;
    height: 2.4em;
    font-size: 1em;
    padding: 0 0.5em;
    font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
    p.form-row input[type=password]::placeholder, p.form-row input[type=text]::placeholder, p.form-row input[type=email]::placeholder, p.form-row input[type=tel]::placeholder {
      color: #179FD2;
      font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
  p.form-row .select2-container, p.form-row p.form-row select {
    width: 100% !important; }
  p.form-row .select2-dropdown {
    top: -1.715em; }
  p.form-row .select2-container--default .select2-selection--single {
    background: 0 0;
    border-color: #179FD2; }
  p.form-row .select2-selection--single .select2-selection__rendered {
    color: #179FD2;
    text-align: left; }
  p.form-row .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #179FD2;
    text-align: left; }
  p.form-row .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #179FD2 transparent transparent; }
  p.form-row textarea {
    width: 100% !important;
    border: 1px solid #179FD2;
    color: #179FD2;
    border-radius: 5px;
    line-height: 1.5em;
    font-size: 1em;
    padding: 1em;
    min-height: 10em;
    font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
    p.form-row textarea::placeholder {
      color: #179FD2;
      font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }

div.clear + p {
  width: 100%;
  float: left; }

input[type=submit] {
  background-color: #179FD2;
  color: #fff;
  font-family: "aileronregular",Arial,"Helvetica Neue",Helvetica,sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  line-height: 2.3em;
  font-size: 1em;
  padding: 0 1em;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out; }
  input[type=submit]:hover {
    background: #FF8000; }

input[type=submit]
fieldset legend {
  font-size: 1.04em;
  padding: .5em 0;
  margin-bottom: 1em;
  color: #00688D;
  border-bottom: 2px solid #DDD; }

.gform_validation_container {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px; }

.content-single .gform_confirmation_wrapper,
.content-single .gform_wrapper {
  width: 100%;
  float: left;
  font-size: 1em;
  background: url(../images/cart-background.svg) center center no-repeat;
  background-size: 100% 100%;
  padding: 1.5em 2em 2em; }
  .content-single .gform_confirmation_wrapper .validation_error,
  .content-single .gform_wrapper .validation_error {
    color: #FF4D00;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: .5em; }
  .content-single .gform_confirmation_wrapper .validation_message,
  .content-single .gform_wrapper .validation_message {
    color: #FF4D00; }
  .content-single .gform_confirmation_wrapper .gform_confirmation_message,
  .content-single .gform_wrapper .gform_confirmation_message {
    color: #00688D;
    text-align: center;
    display: inline-block;
    width: 100%;
    font-family: "aileronbold",Arial,"Helvetica Neue",Helvetica,sans-serif;
    font-size: 1.2em;
    line-height: 1.5em; }

.content-single .gform_body {
  text-align: left;
  margin: 0 -1.2777777778em; }
  .content-single .gform_body ul {
    list-style: none;
    margin: 0; }
  .content-single .gform_body li {
    width: 100%;
    float: left;
    padding: 0 1.2777777778em;
    margin: 0 0 0.5em;
    list-style: none; }
    .content-single .gform_body li.left, .content-single .gform_body li.right {
      width: 50%; }
  .content-single .gform_body label {
    color: #00688D;
    margin-bottom: .1em;
    display: inline-block; }
  .content-single .gform_body input[type=email], .content-single .gform_body input[type=password], .content-single .gform_body input[type=tel], .content-single .gform_body input[type=text] {
    width: 100%;
    border: 1px solid #17A1D3;
    color: #17A1D3;
    border-radius: 5px;
    line-height: 2.3em;
    font-size: 1em;
    padding: 0 0.5em;
    height: 2.4em;
    font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
    .content-single .gform_body input[type=email]::placeholder, .content-single .gform_body input[type=password]::placeholder, .content-single .gform_body input[type=tel]::placeholder, .content-single .gform_body input[type=text]::placeholder {
      color: #17A1D3;
      font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
  .content-single .gform_body .ginput_complex {
    margin: 0 -1.2777777778em; }
    .content-single .gform_body .ginput_complex span {
      width: 100%;
      float: left;
      padding: 0 1.2777777778em;
      margin: 0 0 0.4em; }
      .content-single .gform_body .ginput_complex span.address_city, .content-single .gform_body .ginput_complex span .address_line_1 {
        width: 50%; }
    .content-single .gform_body .ginput_complex label {
      color: #17A1D3; }
  .content-single .gform_body .select2-container, .content-single .gform_body select {
    width: 100% !important; }
  .content-single .gform_body .select2-container--default .select2-selection--single {
    background: 0 0;
    border-color: #17A1D3; }
  .content-single .gform_body .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #17A1D3;
    text-align: left; }
  .content-single .gform_body .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #17A1D3 transparent transparent; }
  .content-single .gform_body textarea {
    width: 100% !important;
    border: 1px solid #17A1D3;
    color: #17A1D3;
    border-radius: 5px;
    line-height: 1.5em;
    font-size: 1em;
    padding: 1em;
    min-height: 10em;
    font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }
    .content-single .gform_body textarea::placeholder {
      color: #17A1D3;
      font-family: "aileronlight",Arial,"Helvetica Neue",Helvetica,sans-serif; }

.gfield_required, .required {
  color: #FF8700 !important; }

/**********************************************************************

Grid

**********************************************************************/
.page-contents {
  overflow-x: hidden; }
  .nav-open .page-contents {
    overflow-x: visible; }

.container {
  max-width: 73.3333333333em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2.3333333333em; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }
  div[role="document"] .container {
    padding: 0 1.6666666667em; }
    .woocommerce-cart div[role="document"] .container {
      padding: 0 2.3333333333em; }
    .woocommerce-checkout div[role="document"] .container {
      padding: 0 2.3333333333em; }
    .woocommerce-account div[role="document"] .container {
      padding: 0 2.3333333333em; }

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.layout {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%; }

.inner {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.row {
  *zoom: 1; }
  .row:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }
  .row.spaced {
    padding-top: 0.5555555556em;
    padding-bottom: 0.5555555556em; }

/**********************************************************************

Header

**********************************************************************/
header.banner {
  position: relative;
  background: #2398C2 url("../images/ricepaper2.png") center center repeat;
  margin-bottom: 2.7777777778em; }
  header.banner svg.background {
    height: 100.5%; }
  header.banner a {
    color: #fff;
    text-decoration: none; }
  header.banner a.nav-toggle {
    display: none; }
  header.banner a.brand {
    float: left;
    margin: 1.6666666667em 0 1.1111111111em;
    width: 10.7222222222em;
    height: 4.9444444444em; }
    header.banner a.brand svg {
      width: 100% !important;
      height: auto !important;
      overflow: visible; }
  header.banner .site-header-store-navigation {
    float: right;
    position: relative;
    background: url("../images/basket-background.svg") top center no-repeat;
    background-size: 100% 100%;
    font-size: 0.7777777778em;
    padding: 0.8571428571em 2.8571428571em 2.1428571429em;
    color: #006C93;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    header.banner .site-header-store-navigation li {
      display: inline-block;
      vertical-align: middle;
      padding: 0 0.7142857143em; }
      header.banner .site-header-store-navigation li a {
        color: #006C93;
        transition: color 0.3s ease-in-out; }
        header.banner .site-header-store-navigation li a:hover {
          color: #FF8000; }
    header.banner .site-header-store-navigation .icon-basket {
      font-size: 1.5em;
      margin-right: 0.3em;
      vertical-align: -0.2em; }
  header.banner a.cart-contents {
    display: inline-block;
    transform: scale3d(0); }
    header.banner a.cart-contents.added {
      color: #FF8000; }
  header.banner .security-info {
    float: right;
    text-align: right;
    color: #fff;
    padding-top: 2em; }
    header.banner .security-info img {
      width: 11em;
      height: auto !important;
      margin-right: -0.5em; }

#search-call {
  float: right;
  padding: 0.8333333333em 0;
  color: #FFBF00; }
  #search-call .icon-search {
    font-size: 1.5em;
    vertical-align: -0.2em;
    margin-left: 0.2em; }

#site-navigation {
  width: 100%;
  float: left;
  border-bottom: 1px solid #6ACBEA;
  margin-bottom: 0.5555555556em; }
  #site-navigation ul {
    float: left; }
  #site-navigation li {
    display: inline-block;
    vertical-align: middle; }
    #site-navigation li.bold {
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    #site-navigation li a {
      display: block;
      padding: 0.8333333333em; }
    #site-navigation li:first-of-type a {
      padding-left: 0; }
  #site-navigation .icon-home {
    font-size: 1.5em;
    vertical-align: -0.2em; }

#breadcrumb {
  width: 100%;
  float: left;
  color: #fff;
  font-size: 0.7777777778em;
  margin-bottom: 2.1428571429em;
  position: relative; }
  #breadcrumb .breadcrumbs {
    float: left; }
    #breadcrumb .breadcrumbs span span a {
      margin-right: 0.3571428571em;
      transition: color 0.3s ease-in-out; }
      #breadcrumb .breadcrumbs span span a:hover {
        color: #FF8000; }
    #breadcrumb .breadcrumbs span span span a, #breadcrumb .breadcrumbs .breadcrumb_last {
      margin: 0 0.3571428571em; }

#social-share {
  float: right;
  margin-right: .2em; }
  #social-share p {
    float: left;
    margin-right: 0.5em; }
  #social-share div {
    float: left; }
    #social-share div * {
      background: 0 0 !important;
      max-width: 100em;
      max-height: 100em; }
  #social-share a {
    float: left;
    font-size: 1.4285714286em;
    margin-left: .25em;
    z-index: 500;
    position: relative;
    font-family: 'icons';
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0.1em !important;
    transition: color 0.3s ease-in-out;
    cursor: pointer; }
    #social-share a:hover {
      color: #FF8000; }
    #social-share a * {
      display: none !important; }
    #social-share a.at-svc-facebook:before {
      content: "\e603"; }
    #social-share a.at-svc-twitter:before {
      content: "\e604"; }

#search-overlay {
  position: fixed;
  z-index: 500;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(38, 165, 207, 0.8);
  display: none;
  opacity: 0; }
  #search-overlay.active {
    display: block; }
  #search-overlay .container {
    height: 100%; }
  #search-overlay .table {
    width: 100%;
    height: 100%; }
  #search-overlay .search-form {
    width: 100%;
    float: left;
    background: #fff;
    color: #FFC000;
    margin-top: -4em;
    transform: translateY(300%);
    opacity: 0; }
    #search-overlay .search-form input {
      border: none;
      color: #FFC000;
      background: #fff;
      font-size: 4.4444444444em;
      padding: .25em;
      height: 1.625em;
      line-height: 1em;
      width: 85%;
      float: left;
      position: relative; }
      #search-overlay .search-form input::placeholder {
        color: #FFC000;
        line-height: 1.2em;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        vertical-align: middle;
        opacity: 1; }
    #search-overlay .search-form button {
      border: none;
      color: #FFC000;
      background: #fff;
      font-size: 4.4444444444em;
      padding: .25em;
      height: 1.625em;
      width: 15%;
      float: right; }

/**********************************************************************

Homepage

**********************************************************************/
#home_header {
  width: 100%;
  float: left;
  padding: 1.1111111111em 0 2.7777777778em; }
  #home_header .intro_image {
    float: right;
    width: 60%;
    position: relative;
    padding-bottom: 25%; }
    #home_header .intro_image svg {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0; }
  #home_header .intro {
    float: right;
    width: 40%;
    padding-top: 2.2222222222em; }
    #home_header .intro h1 {
      color: #fff;
      font-size: 2.5em;
      line-height: 1em;
      margin-bottom: 0.8888888889em; }
      #home_header .intro h1 small {
        font-size: 0.7777777778em;
        font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    #home_header .intro a {
      display: inline-block;
      vertical-align: middle;
      font-size: 0.8333333333em;
      padding: 1.5333333333em 2.3333333333em 1.2em 1.6666666667em;
      position: relative;
      color: #006C92; }
      #home_header .intro a span {
        font-family: "aileronheavy", Arial, "Helvetica Neue", Helvetica, sans-serif;
        position: relative;
        text-transform: uppercase; }
      #home_header .intro a svg {
        overflow: visible; }

.home .content p {
  font-size: 1em; }

#frontpage_buttons {
  width: 100%;
  float: left;
  margin-bottom: 5.5555555556em; }
  #frontpage_buttons li {
    width: 25%;
    float: left;
    padding: 0 0.6666666667em; }
    #frontpage_buttons li a {
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      text-decoration: none;
      position: relative;
      width: 100%;
      text-align: center; }
    #frontpage_buttons li .canvas {
      padding-bottom: 90%; }
    #frontpage_buttons li svg {
      height: auto; }
    #frontpage_buttons li h3 {
      position: relative;
      font-size: 1.6666666667em;
      line-height: 0.8em;
      padding-top: 4.5em;
      margin-bottom: 0.1666666667em; }
    #frontpage_buttons li p {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      font-size: 0.8333333333em;
      line-height: 1em;
      border-bottom: 1px solid #fff; }
    #frontpage_buttons li h4 {
      font-size: 0.8333333333em;
      line-height: 1em;
      color: #fff;
      position: relative; }
    #frontpage_buttons li img {
      position: relative;
      width: 40%;
      height: auto !important; }
  #frontpage_buttons #drumstic_x5F_top {
    transform-origin: 30% 50%; }
  #frontpage_buttons #best_sellers .trumpet {
    transform-origin: 30% 50%; }
  #frontpage_buttons #featured img {
    padding-top: 2.5em; }

#music_illustration {
  overflow: visible; }

#double_bass {
  transform-origin: 50% 70%; }
  #double_bass #trumpet {
    transform-origin: 20% 50%; }
  #double_bass #trombone {
    transform-origin: 20% 50%; }
  #double_bass #sax {
    transform-origin: 45% 20%; }
  #double_bass #drumstick_1 {
    transform-origin: 50% 10%; }
  #double_bass #drumstick_2 {
    transform-origin: 50% 10%; }
  #double_bass #flute {
    transform-origin: 50% 15%; }

#homepage_shortcuts {
  width: 100%;
  float: left;
  margin-bottom: 5.5555555556em; }
  #homepage_shortcuts .attributes {
    width: 50%;
    float: left;
    padding: 0 0.6666666667em; }
  #homepage_shortcuts a {
    transition: color 0.3s ease-in-out; }
    #homepage_shortcuts a:hover {
      color: #FF8000 !important; }
  #homepage_shortcuts .attribute {
    width: 50%;
    float: left;
    border-right: 1px solid #fff; }
    #homepage_shortcuts .attribute h3 {
      padding: 0 0.75em 0.5em; }
    #homepage_shortcuts .attribute ul {
      padding: 0 0.8333333333em; }
    #homepage_shortcuts .attribute.composer h3 {
      color: #00698E; }
    #homepage_shortcuts .attribute.composer ul {
      background: #E5F1F5; }
    #homepage_shortcuts .attribute.series h3 {
      color: #5CBDE2; }
    #homepage_shortcuts .attribute.series ul {
      background: #EFFBFF; }
    #homepage_shortcuts .attribute a {
      display: table-cell;
      height: 100%;
      width: 100%;
      vertical-align: middle;
      text-decoration: none;
      color: #444;
      line-height: 1.3em;
      font-size: 0.8888888889em; }
      #homepage_shortcuts .attribute a span.count {
        float: right; }
  #homepage_shortcuts .category {
    width: 50%;
    float: left;
    padding: 0 0.6666666667em; }
    #homepage_shortcuts .category h3 {
      padding: 0 0 0.5em; }
    #homepage_shortcuts .category a.title, #homepage_shortcuts .category div.actions {
      display: table-cell;
      height: 100%;
      vertical-align: middle;
      text-decoration: none;
      line-height: 0.9em;
      font-size: 0.8888888889em; }
    #homepage_shortcuts .category a.title {
      width: 70%;
      color: #444;
      overflow: hidden; }
    #homepage_shortcuts .category div.actions {
      width: 30%;
      position: relative; }
    #homepage_shortcuts .category span.price {
      padding: 0.6666666667em 0;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
      float: left; }
    #homepage_shortcuts .category .button-wrap {
      float: right; }
    #homepage_shortcuts .category .buy {
      border: none;
      background: none;
      text-decoration: none;
      position: relative;
      color: #fff;
      font-family: "aileronheavy", Arial, "Helvetica Neue", Helvetica, sans-serif;
      text-transform: uppercase;
      font-size: 0.6875em;
      padding: 0.9090909091em;
      cursor: pointer; }
      #homepage_shortcuts .category .buy span {
        position: relative; }
      #homepage_shortcuts .category .buy svg.background {
        overflow: visible; }
  #homepage_shortcuts h3 {
    font-size: 1.1111111111em; }
  #homepage_shortcuts li {
    display: table;
    width: 100%;
    height: 2.9444444444em;
    border-bottom: 1px solid #7CBCD2; }
    #homepage_shortcuts li:last-of-type {
      border-bottom: none; }

#greatest_publishers {
  width: 100%;
  float: left;
  padding: 0px;
  text-align: center; }
  #greatest_publishers li {
    display: inline-block;
    padding: 1.6666666667em; }

/**********************************************************************

Product Listings / Publisher

**********************************************************************/
.header-intro .wrapper {
  color: #fff;
  padding: 0 8.8888888889em 2.7777777778em;
  text-align: center;
  min-height: 12em;
  line-height: 1.4em; }

.header-intro img {
  position: absolute; }

.header-intro .trumpet {
  width: 8.5555555556em;
  height: 13.1111111111em;
  bottom: -1.2222222222em; }
  .header-intro .trumpet.left {
    left: 0.7222222222em; }
  .header-intro .trumpet.right {
    right: -0.5em; }

.header-intro .sax {
  width: 7em;
  height: 12.3333333333em;
  bottom: -1.1111111111em; }
  .header-intro .sax.left {
    left: 1.6666666667em; }
  .header-intro .sax.right {
    right: 1.6666666667em; }

.header-intro .drum {
  width: 6.8333333333em;
  height: 9.2777777778em;
  bottom: 0; }
  .header-intro .drum.left {
    left: 1.1111111111em; }
  .header-intro .drum.right {
    right: 1.1111111111em; }

.header-intro .bass {
  width: 6.8333333333em;
  height: 13.6666666667em;
  bottom: -1.3888888889em; }
  .header-intro .bass.left {
    left: 1.0555555556em; }
  .header-intro .bass.right {
    right: 1.1111111111em; }

.header-intro .flute {
  width: 7.2222222222em;
  height: 13.1111111111em;
  bottom: -1.2222222222em; }
  .header-intro .flute.left {
    left: 1.4444444444em; }
  .header-intro .flute.right {
    right: 1em; }

.header-intro .notes {
  width: 12.8888888889em;
  height: 14.9444444444em;
  bottom: -0.5555555556em; }
  .header-intro .notes.left {
    left: -0.8888888889em; }
  .header-intro .notes.right {
    right: -1.4444444444em; }

.header-intro .trombone {
  width: 7.6111111111em;
  height: 13.5em;
  bottom: -1.4444444444em; }
  .header-intro .trombone.left {
    left: 0.5555555556em; }
  .header-intro .trombone.right {
    right: 1em; }

.header-intro h1 {
  color: #fff;
  font-size: 2.2222222222em;
  line-height: 1em;
  margin-bottom: 0.4444444444em; }
  .header-intro h1.tax-title {
    padding-top: 1.5em; }
  .search .header-intro h1 {
    padding-top: 1.5em; }
    .search .header-intro h1 em {
      color: #FFBF00;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.header-intro h2 {
  color: #fff;
  font-size: 1.3888888889em;
  line-height: 1em;
  margin-bottom: 0.8em; }

.header-intro p {
  font-size: 1em;
  line-height: 1.4em; }

#filters {
  width: 25%;
  float: left;
  margin-bottom: 2em;
  padding-right: .8em; }
  #filters .title {
    width: 100%;
    float: left;
    padding-bottom: 1.1111111111em;
    border-bottom: 1px solid #7CBCD2; }
    #filters .title h3 {
      color: #00688D;
      font-size: .8333333333em;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  #filters .filter {
    width: 100%;
    float: left;
    border-bottom: 1px solid #BDDDE8; }
    #filters .filter h4 {
      display: inline-block;
      width: 100%;
      padding: 1em 0;
      color: #444;
      font-size: .8333333333em;
      cursor: pointer; }
      #filters .filter h4:after {
        content: '+';
        float: right; }
  #filters .widget_layered_nav_filters {
    width: 100%;
    float: left;
    border-bottom: 1px solid #BDDDE8;
    padding: 1em 0; }
    #filters .widget_layered_nav_filters h2 {
      color: #00688D;
      font-size: .8333333333em;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    #filters .widget_layered_nav_filters li {
      display: inline-block;
      margin: 0 0.5em 0.4em 0; }
    #filters .widget_layered_nav_filters a {
      background: url(../images/input-bg.svg) center center no-repeat;
      background-size: 100% 100%;
      color: #fff;
      padding: 0.2em 0.5em;
      text-decoration: none;
      font-size: .8333333333em;
      display: inline-block; }
      #filters .widget_layered_nav_filters a:after {
        content: 'x';
        float: right;
        background: #fff;
        width: 1.05em;
        height: 1.05em;
        line-height: 1em;
        margin: 0.2em 0 0 0.5em;
        color: #26a8d9;
        border-radius: 50%;
        text-align: center;
        font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  #filters .widget_layered_nav {
    width: 100%;
    float: left;
    border-bottom: 1px solid #BDDDE8; }
    #filters .widget_layered_nav h2 {
      display: inline-block;
      width: 100%;
      padding: 1em 0;
      color: #444;
      font-size: .8333333333em;
      cursor: pointer; }
      #filters .widget_layered_nav h2:after {
        content: '+';
        float: right; }
      #filters .widget_layered_nav h2.active:after {
        content: '-'; }
    #filters .widget_layered_nav ul {
      padding-bottom: 2em; }
    #filters .widget_layered_nav a {
      display: block;
      padding: 0.3em 2em 0.3em 0;
      line-height: 1.3em;
      transition: color 0.3s ease-in-out; }
      #filters .widget_layered_nav a:hover {
        color: #FF8000; }
    #filters .widget_layered_nav li {
      font-size: .8333333333em;
      position: relative; }
      #filters .widget_layered_nav li a {
        color: #00688D;
        text-decoration: none; }
      #filters .widget_layered_nav li .count {
        right: 0;
        position: absolute;
        top: 0;
        color: #999; }
  #filters .cta {
    margin-top: 1em; }

.controls {
  font-size: .8333333333em;
  width: 100%;
  float: left;
  padding: 0 0.8em;
  color: #00688D; }
  .controls a {
    color: #00688D;
    transition: color 0.3s ease-in-out; }
    .controls a:hover {
      color: #FF8000; }
  .controls .wrapper {
    padding-bottom: .999043918em;
    border-bottom: 1px solid #7CBCD2;
    overflow: visible;
    display: inline-block; }
  .controls .woocommerce-result-count {
    float: left;
    margin-right: 1em; }
  .controls .woocommerce-ordering {
    float: left; }
  .controls .woocommerce-pagination {
    float: right; }
    .controls .woocommerce-pagination li {
      display: inline-block;
      margin: 0 0.1em; }
  .controls .select2-container {
    width: 11.1111111111em !important;
    margin-top: -0.4444444444em; }
  .controls .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.3333333333em; }
  .controls .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0.4444444444em;
    padding-right: 1.6666666667em;
    color: #00688D; }
  .controls .select2-container .select2-selection--single {
    height: 2.3333333333em; }
  .controls .select2-container--default .select2-selection--single {
    border-color: #00688D; }
  .controls .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.2222222222em;
    position: absolute;
    top: 0.0555555556em;
    right: 0.0555555556em;
    width: 1.6666666667em; }
    .controls .select2-container--default .select2-selection--single .select2-selection__arrow b {
      border-color: #00688D transparent transparent transparent; }
  .controls nav.gridlist-toggle {
    float: right;
    color: #CCE1E8;
    margin-left: 0.8333333333em; }
    .controls nav.gridlist-toggle a {
      text-decoration: none;
      margin-left: 0.2777777778em; }
      .controls nav.gridlist-toggle a.active {
        color: #00688D; }
        .controls nav.gridlist-toggle a.active span {
          color: #7FB3C6; }

#product-list {
  float: right;
  width: 75%; }
  #product-list .woocommerce-info {
    width: 100%;
    float: left;
    padding: 2em;
    text-align: left;
    background: url(../images/list.svg) center center no-repeat;
    text-align: center;
    font-size: 1.6em;
    line-height: 1.1em; }

ul.products {
  width: 100%;
  float: left;
  padding: 1.6666666667em 0; }
  ul.products h3 {
    color: #444;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin-bottom: 1.1111111111em; }
  ul.products strong {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  ul.products p.price {
    font-weight: 1.1111111111em;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    ul.products p.price del {
      padding-right: .3888888889em;
      font-family: "aileronlight_italic", Arial, "Helvetica Neue", Helvetica, sans-serif;
      text-decoration: line-through;
      color: #777; }
    ul.products p.price ins {
      color: #FF8000;
      text-decoration: none;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  ul.products .wrapper {
    padding: 1.6666666667em 1.1111111111em;
    background-size: 100% 100%;
    position: relative; }
  ul.products .options {
    position: absolute;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-transform: uppercase; }
    ul.products .options a {
      background: url(../images/button-more.svg) center center no-repeat;
      color: #FFF;
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: .7222222222em;
      line-height: 1em;
      padding: 0.9230769231em 2.3076923077em; }
    ul.products .options .table {
      position: absolute;
      top: 100%;
      opacity: 0; }
  ul.products button {
    background: url(../images/button-basket-reverse.svg) center center no-repeat;
    color: #FF8000;
    border: 0;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: .7222222222em;
    line-height: 1em;
    height: 3.2307692308em;
    padding: 0 1.5384615385em;
    margin-bottom: 1.1538461538em;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.1s ease-in-out; }
    ul.products button:hover {
      transform: scale(1.1); }
    ul.products button.back {
      float: left;
      border: none;
      background: 0 0;
      color: #00688D;
      font-size: .7777777778em; }
  ul.products li {
    position: relative; }
  ul.products.grid li {
    width: 33.3333333333%;
    float: left;
    padding: 0 0.6666666667em 1.3333333333em;
    text-align: center; }
  ul.products.grid .wrapper {
    background: url(../images/grid.svg) center center no-repeat; }
  ul.products.grid .info {
    width: 100%;
    float: left;
    padding: 1.1111111111em;
    color: #777;
    font-size: .8333333333em;
    position: relative; }
    ul.products.grid .info:before {
      content: '';
      position: absolute;
      height: 1px;
      top: 0;
      left: 15%;
      right: 15%;
      background: #C1C2C3; }
  ul.products.grid .table {
    background: url(../images/grid-over.svg) center center no-repeat; }
  ul.products.list li {
    width: 100%;
    float: left;
    padding: 0 0.6666666667em 0.5em;
    text-align: left; }
  ul.products.list .wrapper {
    background: url(../images/list.svg) center center no-repeat; }
  ul.products.list h3 {
    font-size: 1.3333333333em;
    margin-bottom: 0.1em; }
  ul.products.list .info {
    color: #777; }
    ul.products.list .info p {
      display: inline-block; }
      ul.products.list .info p.publisher {
        font-size: 0.8333333333em;
        margin-right: 0.5555555556em; }
      ul.products.list .info p.series {
        font-size: 0.7222222222em; }
  ul.products.list .table {
    background: url(../images/list-over.svg) center center no-repeat;
    text-align: center; }
    ul.products.list .table a {
      display: inline-block; }
  ul.products.list .price {
    margin-top: -2.8em;
    float: right; }
  ul.products.list form {
    display: inline-block; }
  ul.products.list button {
    display: inline-block;
    margin-right: 0.5555555556em;
    margin-bottom: 0; }

/**********************************************************************

Publisher Listing

**********************************************************************/
.post-type-archive-publisher .articles article .image {
  width: 40%;
  float: left;
  text-align: center; }
  .post-type-archive-publisher .articles article .image img {
    max-width: 100%; }

.post-type-archive-publisher .articles article .table {
  background: url(../images/outline-box.svg) center center no-repeat;
  padding: 1.9444444444em 1.2777777778em;
  background-size: 100% 100%; }

.post-type-archive-publisher .articles article .description {
  width: 60%;
  float: left;
  padding-left: 1.6111111111em;
  line-height: 1.2em; }

.post-type-archive-publisher .articles article .entry-summary {
  min-height: 5em; }

.post-type-archive-publisher .articles article footer {
  border-bottom: none; }

.post-type-archive-publisher .articles article .line {
  width: 100%;
  float: left;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  border-bottom: 1px solid #DDD; }

/**********************************************************************

Series A-Z

**********************************************************************/
.content-single .serives-az {
  margin: 0 -1.2777777778em; }

.content-single .series-list {
  width: 33.3333333333%;
  float: left;
  padding: 0 1.2777777778em; }
  .content-single .series-list h2 {
    color: #00688D;
    font-size: .8333333333em;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    padding-bottom: 1em;
    border-bottom: 1px solid #7CBCD2; }
  .content-single .series-list:nth-of-type(3n+4) {
    clear: both; }
  .content-single .series-list ul {
    list-style: none;
    margin: 0;
    padding: 0 0 2em 0; }
    .content-single .series-list ul li {
      list-style: none;
      margin: 0;
      padding: 0; }
  .content-single .series-list a {
    display: inline-block;
    width: 100%;
    color: #00688D;
    transition: color 0.3s ease-in-out;
    text-decoration: none; }
    .content-single .series-list a:hover {
      color: #FF8000; }
    .content-single .series-list a span {
      float: right;
      color: #999; }

/**********************************************************************

Single Product

**********************************************************************/
.single-product .product {
  width: 100%;
  float: left; }

.single-product .summary {
  width: 63%;
  float: left;
  padding: 0 0.7777777778em 4.4444444444em;
  line-height: 1.5em; }
  .single-product .summary p {
    font-size: 0.8888888889em;
    line-height: 1.5em; }

.single-product .sidebar {
  width: 37%;
  float: left;
  float: left;
  padding: 0 0.7777777778em; }

.single-product h1 {
  font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif;
  width: 90%;
  color: #00698E;
  font-size: 2.2222222222em;
  line-height: 1.1em;
  margin-bottom: .3em; }

.single-product .code {
  color: #00688D; }

.single-product .publisher {
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #5AA0B9; }

.single-product .info {
  width: 100%;
  float: left;
  color: #25A8D9; }
  .single-product .info p {
    float: left;
    padding-right: 1.3888888889em; }
  .single-product .info strong {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.single-product .shop_attributes {
  margin: 3em 0;
  font-size: 0.8888888889em;
  line-height: 1.8em; }
  .single-product .shop_attributes p {
    font-size: 1em; }
  .single-product .shop_attributes th {
    padding-right: 2em;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.single-product h2 {
  font-size: 1.11em;
  padding-bottom: .5em;
  border-bottom: 1px solid #DDD;
  margin-bottom: .5em; }

.single-product h3 {
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.single-product .sidebare {
  text-align: center; }

.single-product .add-to-cart {
  text-align: center;
  background: url(../images/basket_bg.svg) center center no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding: 2em 3em;
  margin-bottom: 1em; }
  .single-product .add-to-cart form {
    position: relative; }
  .single-product .add-to-cart .price {
    font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #fff;
    font-size: 1.4444444444em;
    margin-bottom: .5em; }
    .single-product .add-to-cart .price ins {
      text-decoration: none; }
    .single-product .add-to-cart .price del {
      color: #545454;
      font-family: "aileronlight_italic", Arial, "Helvetica Neue", Helvetica, sans-serif;
      text-decoration: line-through;
      margin-right: .3em; }
  .single-product .add-to-cart div.qty {
    font-family: "aileronlight_italic", Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin-bottom: 1em; }
    .single-product .add-to-cart div.qty .quantity, .single-product .add-to-cart div.qty span {
      display: inline-block;
      margin: 0 0.2em; }
    .single-product .add-to-cart div.qty input {
      display: inline-block;
      font-size: .9444444444em;
      color: #fff;
      text-align: center;
      width: 2.5em;
      height: 2.5em;
      background: 0 0;
      border: 1px solid #fff;
      border-radius: 5px; }
  .single-product .add-to-cart button {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #FF8000;
    font-size: .7222222222em;
    text-transform: uppercase;
    line-height: 1em;
    height: 3.2em;
    padding: 0 2em;
    position: relative;
    transition: all 0.1s ease-in-out; }
    .single-product .add-to-cart button:hover {
      transform: scale(1.1); }

.button-wrap {
  display: inline-block; }

.starfield {
  position: absolute;
  top: -1em;
  right: 0;
  bottom: -1em;
  left: 0;
  color: #FFBF00;
  z-index: 600; }
  .starfield i {
    opacity: 0;
    text-shadow: 0px 0px 3px #FF8000; }

#reviews {
  width: 100%;
  float: left;
  margin-top: 3em; }
  #reviews li {
    width: 100%;
    float: left;
    margin-top: 1.5em; }
  #reviews p.meta {
    font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  #reviews .verified {
    font-family: "aileronlight_italic", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #FF8000; }
  #reviews .description {
    width: 70%;
    float: left;
    padding-right: 2em;
    font-size: .95em;
    line-height: 1.4em; }
  #reviews .star-rating {
    width: 30%;
    float: left;
    background: url(../images/review-bg.svg) center center no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 1.5em 0; }
    #reviews .star-rating .text {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px; }
    #reviews .star-rating i {
      color: #F6D1AC; }
      #reviews .star-rating i.select {
        color: #FF8000; }
  #reviews .must-log-in a {
    color: #25A8D9;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    #reviews .must-log-in a:hover {
      color: #FF8000; }

#review_form_wrapper {
  width: 100%;
  float: left;
  margin-top: 2em; }
  #review_form_wrapper p {
    width: 100%;
    float: left;
    margin-bottom: 0.5em; }
  #review_form_wrapper textarea {
    width: 100%;
    border: 1px solid #17A1D3;
    color: #17A1D3;
    border-radius: 5px;
    line-height: 2.3em;
    font-size: 1em;
    line-height: 1.5em;
    padding: 0.5em 1em;
    min-height: 3em;
    font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    #review_form_wrapper textarea::placeholder {
      color: #17A1D3;
      font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  #review_form_wrapper .stars a {
    text-decoration: none;
    font-size: 1.3em;
    color: #F6D1AC;
    transition: color 0.3s ease-in-out; }
    #review_form_wrapper .stars a.active {
      color: #FF8000; }
    #review_form_wrapper .stars a span {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px; }
  #review_form_wrapper input[type=submit] {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #17A1D3;
    border: 1px solid #17A1D3;
    font-size: .7222222222em;
    text-transform: uppercase;
    line-height: 1em;
    height: 3.2em;
    padding: 0 2em;
    cursor: pointer; }

.cta {
  width: 100%;
  float: left;
  color: #fff;
  text-align: center;
  padding: 1.5em 2.3em;
  margin-bottom: 1em;
  background-size: 100% 100%; }
  .cta h3 {
    font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 1.1111111111em;
    margin-bottom: .4em; }
  .cta p {
    font-size: 0.8888888889em;
    line-height: 1.2em; }
  .cta a {
    color: #fff;
    text-decoration: none; }
  .cta.delivery {
    background: url(../images/box_bg.svg) center center no-repeat;
    background-size: 100% 100%;
    padding-top: 3em; }
  .cta.box {
    background: url(../images/delivery_bg.svg) center center no-repeat;
    background-size: 100% 100%; }
    .cta.box .trumpet {
      width: 5em !important;
      height: 2.3888888889em !important; }

/**********************************************************************

Posts Listing

**********************************************************************/
.articles {
  margin: 0 -0.7777777778em; }
  .articles article {
    width: 50%;
    float: left;
    padding: 0 1.4444444444em;
    margin-bottom: 2em; }
    .articles article a {
      color: #00688D;
      text-decoration: none; }
    .articles article h2 {
      font-size: 2.2222222222em;
      line-height: 1.1em; }
    .articles article time {
      color: #00688D;
      font-size: 0.8333333333em;
      line-height: 1.5em; }
    .articles article .entry-summary {
      font-size: 0.8888888889em; }
    .articles article footer {
      border-bottom: 1px solid #DDDDDD; }

/**********************************************************************

Single / Page

**********************************************************************/
.content-single h1, .content-single h2, .content-single h3, .content-single h4, .content h1, .content h2, .content h3, .content h4 {
  font-weight: normal;
  line-height: 1.2; }

.content-single h1, .content h1 {
  font-style: normal;
  font-size: 2.2222222222em;
  margin-bottom: 0.3em;
  color: #00678C; }

.content-single h2, .content h2 {
  font-style: normal;
  font-size: 1.9444444444em;
  margin-bottom: 1em;
  color: #0085B2; }

.content-single h3, .content h3 {
  font-size: 1.2222222222em;
  margin: 1.1363636364em 0;
  color: #818183;
  font-style: normal; }

.content-single h4, .content h4 {
  font-size: 1em;
  margin: 1.3888888889em 0 0.5em;
  font-weight: 700; }

.content-single h5, .content h5 {
  margin: 1.3888888889em 0 0.5em; }

.content-single h5, .content h5 {
  margin: 1.3888888889em 0 0.5em; }

.content-single mark, .content mark {
  background: #c3eaf7;
  padding: 0.1em 0.3em;
  color: #111; }

.content-single p, .content p {
  font-size: 0.8888888889em;
  line-height: 1.5em;
  margin-bottom: 1.5em; }

.content-single ul, .content-single ol, .content ul, .content ol {
  margin: 1.3888888889em 1.1em; }

.content-single ul li, .content-single ol li, .content ul li, .content ol li {
  font-size: 0.7777777778em;
  line-height: 160%;
  list-style: disc;
  margin-bottom: 0.3571428571em; }

.content-single ol li, .content ol li {
  list-style: decimal; }

.content-single strong, .content strong {
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.content-single small, .content small {
  font-size: 0.75em; }

.content-single a, .content a {
  font-weight: 700;
  text-decoration: underline; }

.content-single img, .content img {
  max-width: 100%;
  height: auto; }

.content-single .size-full, .content .size-full {
  width: 100%;
  height: auto; }

.content-single .alignnone, .content .alignnone {
  float: none;
  display: block;
  margin: 0 0 1em 0; }

.content-single .alignleft, .content .alignleft {
  float: left;
  margin: 0 0.5em 1em 0; }

.content-single .alignright, .content .alignright {
  float: right;
  margin: 0 0 1em 0.5em; }

.content-single .aligncenter, .content .aligncenter {
  display: block;
  margin: 0 auto 1em auto; }

.content-single .wp-caption, .content .wp-caption {
  border: none;
  text-align: center;
  background: lightyellow;
  padding: 0.5em;
  margin: 0 0 1em 0; }

.content-single table, .content table {
  font-weight: 300;
  margin-bottom: 1em;
  border-top: 1px solid black;
  border-left: 1px solid black; }
  .content-single table th, .content table th {
    color: #fff;
    background: black;
    padding: 0.5em; }
  .content-single table td, .content table td {
    font-weight: 300;
    padding: 0.5em;
    border-right: 1px solid black;
    border-bottom: 1px solid black; }

.content-single ol, .content ol {
  font-size: 1em;
  line-height: 140%;
  list-style: square;
  margin: 0 1.5em 0.5em; }
  .content-single ol li, .content ol li {
    margin: 0 0 0.5em 0.25em; }

.content-single ul, .content ul {
  font-size: 1em;
  line-height: 140%;
  list-style: square;
  margin: 0 1.5em 0.5em; }
  .content-single ul li, .content ul li {
    margin: 0 0 0.5em 0.25em; }

.content-single .wp-caption, .content .wp-caption {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  width: 100%; }
  .content-single .wp-caption .wp-caption-text, .content .wp-caption .wp-caption-text {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    zoom: 1;
    *display: inline;
    width: 100%;
    font-size: 0.7222222222em;
    line-height: 1.2em;
    padding: 0.7692307692em 1.5384615385em;
    margin: 0 0 1.9230769231em; }

.content-single blockquote, .content blockquote {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  zoom: 1;
  *display: inline;
  width: 100%;
  font-size: 1.2222222222em; }
  .content-single blockquote small, .content blockquote small {
    font-size: 0.6818181818em;
    margin-bottom: -1em;
    float: left; }
  .content-single blockquote p, .content blockquote p {
    margin: 0 0 1.1363636364em; }
  .content-single blockquote a, .content blockquote a {
    font-size: 0.6818181818em; }

.content-single figure, .content figure {
  margin: 0;
  border: none;
  max-width: 100% !important; }

.content-single .entry-content-asset, .content .entry-content-asset {
  width: 100%;
  float: left;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .content-single .entry-content-asset iframe, .content-single .entry-content-asset object, .content-single .entry-content-asset embed, .content .entry-content-asset iframe, .content .entry-content-asset object, .content .entry-content-asset embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.content-single iframe, .content iframe {
  max-width: 100% !important; }

.content-single .content, .content .content {
  border-top: 0;
  padding-top: 0;
  margin-top: 0; }

.content-single header, .content header {
  text-align: center;
  margin-bottom: 1.5em;
  padding: 0 5.5555555556em; }
  .content-single header time, .content header time {
    color: #00688D;
    font-size: 0.8333333333em; }

.content-single .highlight-content, .content .highlight-content {
  color: #00BAE8;
  padding: 3.8888888889em 5.5555555556em 1.1111111111em;
  text-align: center; }
  .content-single .highlight-content.intro, .content .highlight-content.intro {
    padding-top: 0;
    padding-bottom: 0; }
  .content-single .highlight-content *, .content .highlight-content * {
    font-size: 1em;
    margin-bottom: 1em; }

.content-single .full-content, .content .full-content {
  padding: 1.1111111111em 5.5555555556em 1.1111111111em;
  text-align: center; }

.content-single .image-left-content, .content-single .image-right-content, .content .image-left-content, .content .image-right-content {
  padding: 3.8888888889em; }
  .content-single .image-left-content .content, .content-single .image-right-content .content, .content .image-left-content .content, .content .image-right-content .content {
    width: 55%;
    float: left;
    padding: 0 1.2777777778em; }

.content-single .half-content .content, .content .half-content .content {
  width: 50%;
  float: left;
  padding: 0 1.2777777778em; }

.content-single .image, .content .image {
  width: 45%;
  float: left;
  padding: 0 1.2777777778em;
  text-align: center; }

.content-single .table, .content .table {
  background: url(../images/image-bg.svg) center center no-repeat;
  padding: 0 1.2777777778em;
  background-size: 100% 100%; }

.content-single .media, .content .media {
  margin: 3.8888888889em 11.1111111111em; }
  .content-single .media .meida-outer, .content .media .meida-outer {
    padding: 2.1111111111em 1.9444444444em 1.9444444444em;
    background: url(../images/image-bg.svg) center center no-repeat; }
  .content-single .media .meida-inner, .content .media .meida-inner {
    height: 0;
    padding-top: 25px;
    padding-bottom: 56.34%;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden; }
    .content-single .media .meida-inner iframe,
    .content-single .media .meida-inner object,
    .content-single .media .meida-inner embed, .content .media .meida-inner iframe,
    .content .media .meida-inner object,
    .content .media .meida-inner embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .content-single .media .meida-inner img, .content .media .meida-inner img {
      width: 100% !important;
      height: auto; }

.content-single .contact-form, .content .contact-form {
  padding: 3.8888888889em; }
  .content-single .contact-form .form-content, .content .contact-form .form-content {
    width: 45%;
    float: left;
    padding: 0 1.2777777778em; }
  .content-single .contact-form .form, .content .contact-form .form {
    width: 55%;
    float: left;
    padding: 0 1.2777777778em; }

.content-single .divide, .content .divide {
  margin: 0 11.1111111111em;
  border-bottom: 1px solid #DDDDDD; }
  .content-single .divide:after, .content .divide:after {
    content: "";
    display: table;
    clear: both; }

.content-single .row:after, .content .row:after {
  content: "";
  display: table;
  clear: both; }

/**********************************************************************

About Page

**********************************************************************/
.contact .map {
  width: 100%;
  float: left;
  padding-bottom: 80%;
  position: relative; }
  .contact .map .map-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .contact .map .map-canvas {
    display: block;
    width: 100%;
    height: 100%; }

/**********************************************************************

About Page

**********************************************************************/
.about-us .intro-content {
  margin: 0 8.3333333333em 3em;
  text-align: center; }

.profile.row {
  margin: 0 -1.2777777778em 2em; }
  .profile.row .image-outer {
    width: 40%;
    float: left;
    padding: 0 0 35%;
    position: relative; }
    .profile.row .image-outer .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 1.2777777778em;
      text-align: center; }
  .profile.row img {
    padding: 2em 2em 1.5em; }
  .profile.row .content {
    width: 60%;
    float: left;
    padding: 0 1.2777777778em; }
  .profile.row h2 {
    margin-bottom: 0.2em; }
  .profile.row h3 {
    margin: 0 0 0.5em;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #5AA0B9; }
  .profile.row dl.contact-details {
    width: 100%;
    float: left;
    color: #00688D;
    margin-bottom: 0.2em;
    line-height: 1.3em; }
    .profile.row dl.contact-details dt {
      width: 15%;
      float: left; }
    .profile.row dl.contact-details dd {
      width: 85%;
      float: left; }
    .profile.row dl.contact-details a {
      text-decoration: none;
      color: #00688D; }
  .profile.row p.social {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #25A7D8;
    font-size: 0.9285714286em; }
    .profile.row p.social span.title {
      display: inline-block;
      width: 15%; }
    .profile.row p.social span.text {
      font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .profile.row p.social a {
      color: #25A7D8;
      text-decoration: none; }
  .profile.row .divider {
    display: inline-block;
    height: 0;
    width: 100%;
    border-bottom: 1px solid #DDDDDD; }
  .profile.row h4 {
    font-size: 0.8333333333em;
    margin: 0 0 1em;
    color: #444444; }
  .profile.row dl.fun-facts {
    width: 100%;
    float: left;
    font-size: 0.7777777778em;
    margin-bottom: 1em;
    line-height: 1.5em; }
    .profile.row dl.fun-facts dt {
      width: 30%;
      float: left; }
    .profile.row dl.fun-facts dd {
      width: 70%;
      float: left; }
  .profile.row button.blue.button {
    border: none;
    float: right;
    margin-top: -3em; }
  .profile.row .draw-outer {
    width: 100%;
    float: left; }
  .profile.row .draw {
    padding: 1.2777777778em 0 0; }

/**********************************************************************

Login/Sign up

**********************************************************************/
.woocommerce-account .content-single {
  padding: 2em 5.5555555556em 0; }
  .woocommerce-account .content-single header {
    text-align: left;
    padding: 0; }
  .woocommerce-account .content-single p {
    font-size: 1em;
    line-height: 1.5em; }
  .woocommerce-account .content-single a {
    color: #26A8D9;
    text-decoration: none;
    transition: color 0.3s ease-in-out; }
    .woocommerce-account .content-single a:hover {
      color: #FF8000 !important; }

.woocommerce-account #customer_login {
  margin: 0 -1.1111111111em; }
  .woocommerce-account #customer_login button {
    border: 0;
    background: url(../images/button-update.svg) center center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 1.05em;
    padding: 0.8em 1em;
    cursor: pointer; }
  .woocommerce-account #customer_login h2 {
    font-size: 1.04em;
    padding: .5em 0;
    color: #00688D;
    border-bottom: 2px solid #DDD; }
  .woocommerce-account #customer_login .col-1 {
    width: 60%;
    float: left;
    padding: 0 1.1111111111em;
    margin-bottom: 3em; }
  .woocommerce-account #customer_login .form-row-wide label {
    float: left;
    width: 100%; }
  .woocommerce-account #customer_login .form-row-wide input {
    max-width: 25em;
    clear: both; }
  .woocommerce-account #customer_login .col-2 {
    width: 40%;
    float: left;
    padding: 0 1.1111111111em;
    margin-bottom: 3em; }
    .woocommerce-account #customer_login .col-2 h2 {
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .woocommerce-account #customer_login .col-2 label {
      color: #00688D; }
    .woocommerce-account #customer_login .col-2 form {
      width: 100%;
      float: left;
      background: url(../images/cart-background.svg) center center no-repeat;
      background-size: 100% 100%;
      padding: 1em 2em 0; }
    .woocommerce-account #customer_login .col-2 button {
      background-image: url(../images/button-form-submit.svg); }
  .woocommerce-account #customer_login p.form-row {
    width: 100%;
    float: left; }
  .woocommerce-account #customer_login label {
    color: #17A1D3;
    margin-bottom: 0.6em;
    display: inline-block; }

.woocommerce-account input[type=text], .woocommerce-account input[type=password], .woocommerce-account input[type=email] {
  width: 100%;
  border: 1px solid #17A1D3;
  color: #17A1D3;
  border-radius: 5px;
  line-height: 2.3em;
  font-size: 1em;
  padding: 0 0.5em;
  height: 2.4em;
  font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .woocommerce-account input[type=text]::placeholder, .woocommerce-account input[type=password]::placeholder, .woocommerce-account input[type=email]::placeholder {
    color: #17A1D3;
    font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif; }

.woocommerce-account .my_account_orders {
  margin-bottom: 2em; }

.woocommerce-account .order-total {
  text-align: center; }

.woocommerce-account .order-actions {
  text-align: right; }
  .woocommerce-account .order-actions a {
    margin: 0 0 0 0.2em;
    border: 1px solid #26A8D9;
    border-radius: 5px;
    padding: 0.5em 1em;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out; }
    .woocommerce-account .order-actions a:hover {
      border-color: #FF8000;
      color: #FF8000 !important; }

.content-single .col2-set.addresses {
  width: 100%;
  float: left;
  margin-bottom: 3em; }
  .content-single .col2-set.addresses h3 {
    display: inline-block;
    padding: 0 0.5em 0.5em 0;
    font-size: 1em;
    margin-bottom: 0;
    color: #00688D;
    border-bottom: 2px solid #DDD; }
  .content-single .col2-set.addresses a {
    font-size: 0.8em;
    border: 1px solid #26A8D9;
    border-radius: 5px;
    padding: 0.5em 1em;
    text-transform: uppercase;
    margin-left: 0.5em;
    transition: all 0.3s ease-in-out; }
    .content-single .col2-set.addresses a:hover {
      border-color: #FF8000;
      color: #FF8000; }
  .content-single .col2-set.addresses .col-1, .content-single .col2-set.addresses col-2 {
    width: 50%;
    float: left; }

.col-1.address, .col-2.address {
  width: 50%;
  float: left; }

.woocommerce-account .order-summary {
  width: 100%;
  float: left;
  margin-bottom: 3em; }
  .woocommerce-account .order-summary .message {
    font-size: 1.2em;
    margin-bottom: 0.5em; }
  .woocommerce-account .order-summary h2 {
    margin: 0.5em 0 0.5em; }
  .woocommerce-account .order-summary table.shop_table {
    margin-bottom: 2em; }
  .woocommerce-account .order-summary header {
    padding: 0;
    text-align: left; }
  .woocommerce-account .order-summary .product-total {
    text-align: right; }
  .woocommerce-account .order-summary tfoot th {
    border-bottom: 1px solid #DDD !important; }
  .woocommerce-account .order-summary tfoot td {
    text-align: right; }
  .woocommerce-account .order-summary .customer_details th {
    border-bottom: 1px solid #DDD !important; }
  .woocommerce-account .order-summary .customer_details td {
    text-align: right; }
  .woocommerce-account .order-summary .col2-set {
    width: 100%;
    float: left;
    margin: 0 -1.1111111111em; }
    .woocommerce-account .order-summary .col2-set .col-1, .woocommerce-account .order-summary .col2-set .col-2 {
      width: 50%;
      float: left;
      padding: 0 1.1111111111em; }

/**********************************************************************

Cart

**********************************************************************/
.woocommerce-cart .woocommerce, .woocommerce-checkout .woocommerce {
  margin: 0 -1.1111111111em; }

.woocommerce-cart .woocommerce-info, .woocommerce-cart .woocommerce-message, .woocommerce-checkout .woocommerce-info, .woocommerce-checkout .woocommerce-message {
  padding: 0 1.1111111111em;
  font-size: 1.04em;
  color: #FF8000;
  font-family: "aileronbold",Arial,"Helvetica Neue",Helvetica,sans-serif;
  margin-bottom: 1em; }
  .woocommerce-cart .woocommerce-info a, .woocommerce-cart .woocommerce-message a, .woocommerce-checkout .woocommerce-info a, .woocommerce-checkout .woocommerce-message a {
    font-family: "aileronheavy", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #e67300; }

.woocommerce-cart p.form-row, .woocommerce-checkout p.form-row {
  padding: 0 1.715em !important; }
  .woocommerce-cart p.form-row.form-row-first, .woocommerce-checkout p.form-row.form-row-first {
    width: 50%; }
  .woocommerce-cart p.form-row.form-row-last, .woocommerce-checkout p.form-row.form-row-last {
    width: 50%; }
  .woocommerce-cart p.form-row#billing_country_field,
  .woocommerce-cart p.form-row#shipping_country_field, .woocommerce-checkout p.form-row#billing_country_field,
  .woocommerce-checkout p.form-row#shipping_country_field {
    width: 50%; }
  .woocommerce-cart p.form-row#billing_first_name_field,
  .woocommerce-cart p.form-row#shipping_first_name_field, .woocommerce-checkout p.form-row#billing_first_name_field,
  .woocommerce-checkout p.form-row#shipping_first_name_field {
    clear: both; }
  .woocommerce-cart p.form-row#billing_city_field,
  .woocommerce-cart p.form-row#shipping_city_field, .woocommerce-checkout p.form-row#billing_city_field,
  .woocommerce-checkout p.form-row#shipping_city_field {
    width: 50%; }
  .woocommerce-cart p.form-row#billing_email_field,
  .woocommerce-cart p.form-row#shipping_email_field, .woocommerce-checkout p.form-row#billing_email_field,
  .woocommerce-checkout p.form-row#shipping_email_field {
    clear: both; }

.woocommerce-cart .cart-empty, .woocommerce-checkout .cart-empty {
  padding: 0 1.1111111111em; }

.woocommerce-cart .button.empty, .woocommerce-checkout .button.empty {
  font-size: 0.8333333333em;
  text-align: center;
  border: 0;
  color: #fff;
  background: url(../images/button-grey.svg) center center no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding: 0.8em;
  cursor: pointer;
  text-decoration: none;
  display: inline-block; }

.woocommerce-cart .basket, .woocommerce-cart .col2-set, .woocommerce-checkout .basket, .woocommerce-checkout .col2-set {
  width: 60%;
  float: left;
  padding: 0 1.1111111111em; }
  .woocommerce-cart .basket p.backorder_notification, .woocommerce-cart .col2-set p.backorder_notification, .woocommerce-checkout .basket p.backorder_notification, .woocommerce-checkout .col2-set p.backorder_notification {
    font-size: 13px;
    display: inline-block;
    margin: 0px;
    padding-left: 5px; }
    .woocommerce-cart .basket p.backorder_notification:before, .woocommerce-cart .col2-set p.backorder_notification:before, .woocommerce-checkout .basket p.backorder_notification:before, .woocommerce-checkout .col2-set p.backorder_notification:before {
      content: " - "; }

.woocommerce-cart .totals-outer, .woocommerce-checkout .totals-outer {
  width: 100%;
  float: left;
  text-align: center;
  background: url(../images/cart-background.svg) center center no-repeat;
  background-size: 100% 100%;
  padding-bottom: 1em;
  margin-bottom: 1em; }
  .woocommerce-cart .totals-outer button, .woocommerce-checkout .totals-outer button {
    border: 0;
    background: url(../images/button-update.svg) center center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 1.1111111111em;
    padding: 0.8em 1em;
    cursor: pointer; }
    .woocommerce-cart .totals-outer button[name=update_cart], .woocommerce-checkout .totals-outer button[name=update_cart] {
      padding: 0.8em 5em; }
  .woocommerce-cart .totals-outer input, .woocommerce-checkout .totals-outer input {
    border: 1px solid #fff;
    background: #C3EAF7;
    color: #00688D;
    text-align: left;
    border-radius: 5px;
    padding: 0 0.5em;
    width: 100%;
    line-height: 2.3em;
    height: 2.3em;
    font-size: 1em; }
    .woocommerce-cart .totals-outer input::placeholder, .woocommerce-checkout .totals-outer input::placeholder {
      color: #00688D; }
  .woocommerce-cart .totals-outer a.shipping-calculator-button, .woocommerce-checkout .totals-outer a.shipping-calculator-button {
    color: #777777;
    display: inline-block;
    margin-top: 0.8em; }

.woocommerce-cart .cart-collaterals, .woocommerce-cart .col-last, .woocommerce-checkout .cart-collaterals, .woocommerce-checkout .col-last {
  width: 40%;
  float: left;
  padding: 0 1.1111111111em 2em; }
  .woocommerce-cart .cart-collaterals h2, .woocommerce-cart .col-last h2, .woocommerce-checkout .cart-collaterals h2, .woocommerce-checkout .col-last h2 {
    font-size: 1.04em;
    padding: .5em 0;
    color: #00688D;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    border-bottom: 2px solid #DDDDDD; }
  .woocommerce-cart .cart-collaterals table, .woocommerce-cart .col-last table, .woocommerce-checkout .cart-collaterals table, .woocommerce-checkout .col-last table {
    width: 100%;
    border: none;
    text-align: left; }
    .woocommerce-cart .cart-collaterals table th, .woocommerce-cart .col-last table th, .woocommerce-checkout .cart-collaterals table th, .woocommerce-checkout .col-last table th {
      background: none;
      color: #00688D;
      border-bottom: 1px solid #fff;
      padding: 1em 2em; }
      .woocommerce-cart .cart-collaterals table th.product-total, .woocommerce-cart .col-last table th.product-total, .woocommerce-checkout .cart-collaterals table th.product-total, .woocommerce-checkout .col-last table th.product-total {
        text-align: right; }
    .woocommerce-cart .cart-collaterals table td, .woocommerce-cart .col-last table td, .woocommerce-checkout .cart-collaterals table td, .woocommerce-checkout .col-last table td {
      color: #00688D;
      border-bottom: 1px solid #fff;
      border-right: none;
      text-align: right;
      padding: 1em 2em; }
      .woocommerce-cart .cart-collaterals table td.product-name, .woocommerce-cart .col-last table td.product-name, .woocommerce-checkout .cart-collaterals table td.product-name, .woocommerce-checkout .col-last table td.product-name {
        text-align: left; }
    .woocommerce-cart .cart-collaterals table .cart_item td, .woocommerce-cart .col-last table .cart_item td, .woocommerce-checkout .cart-collaterals table .cart_item td, .woocommerce-checkout .col-last table .cart_item td {
      padding: 1em 2.4em;
      color: #17A2D4;
      line-height: 1.2em; }
    .woocommerce-cart .cart-collaterals table .order-total th, .woocommerce-cart .col-last table .order-total th, .woocommerce-checkout .cart-collaterals table .order-total th, .woocommerce-checkout .col-last table .order-total th {
      border-bottom: none; }
    .woocommerce-cart .cart-collaterals table .order-total td, .woocommerce-cart .col-last table .order-total td, .woocommerce-checkout .cart-collaterals table .order-total td, .woocommerce-checkout .col-last table .order-total td {
      padding-right: 1.05em;
      border-bottom: none; }
  .woocommerce-cart .cart-collaterals .woocommerce-checkout-review-order-table, .woocommerce-cart .col-last .woocommerce-checkout-review-order-table, .woocommerce-checkout .cart-collaterals .woocommerce-checkout-review-order-table, .woocommerce-checkout .col-last .woocommerce-checkout-review-order-table {
    margin-bottom: 0; }
    .woocommerce-cart .cart-collaterals .woocommerce-checkout-review-order-table thead, .woocommerce-cart .col-last .woocommerce-checkout-review-order-table thead, .woocommerce-checkout .cart-collaterals .woocommerce-checkout-review-order-table thead, .woocommerce-checkout .col-last .woocommerce-checkout-review-order-table thead {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px; }
  .woocommerce-cart .cart-collaterals .shipping th, .woocommerce-cart .col-last .shipping th, .woocommerce-checkout .cart-collaterals .shipping th, .woocommerce-checkout .col-last .shipping th {
    vertical-align: top;
    padding-top: 1.45em; }
  .woocommerce-cart .cart-collaterals .shipping td, .woocommerce-cart .col-last .shipping td, .woocommerce-checkout .cart-collaterals .shipping td, .woocommerce-checkout .col-last .shipping td {
    vertical-align: top; }
  .woocommerce-cart .cart-collaterals select, .woocommerce-cart .cart-collaterals .select2-container, .woocommerce-cart .col-last select, .woocommerce-cart .col-last .select2-container, .woocommerce-checkout .cart-collaterals select, .woocommerce-checkout .cart-collaterals .select2-container, .woocommerce-checkout .col-last select, .woocommerce-checkout .col-last .select2-container {
    width: 100% !important; }
  .woocommerce-cart .cart-collaterals .select2-container--default .select2-selection--single, .woocommerce-cart .col-last .select2-container--default .select2-selection--single, .woocommerce-checkout .cart-collaterals .select2-container--default .select2-selection--single, .woocommerce-checkout .col-last .select2-container--default .select2-selection--single {
    background: none;
    border-color: #fff; }
  .woocommerce-cart .cart-collaterals .select2-container--default .select2-selection--single .select2-selection__rendered, .woocommerce-cart .col-last .select2-container--default .select2-selection--single .select2-selection__rendered, .woocommerce-checkout .cart-collaterals .select2-container--default .select2-selection--single .select2-selection__rendered, .woocommerce-checkout .col-last .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #00688D;
    text-align: left; }
  .woocommerce-cart .cart-collaterals .select2-container--default .select2-selection--single .select2-selection__arrow b, .woocommerce-cart .col-last .select2-container--default .select2-selection--single .select2-selection__arrow b, .woocommerce-checkout .cart-collaterals .select2-container--default .select2-selection--single .select2-selection__arrow b, .woocommerce-checkout .col-last .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #00688D transparent transparent transparent; }
  .woocommerce-cart .cart-collaterals .order-total, .woocommerce-cart .col-last .order-total, .woocommerce-checkout .cart-collaterals .order-total, .woocommerce-checkout .col-last .order-total {
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
    .woocommerce-cart .cart-collaterals .order-total td, .woocommerce-cart .col-last .order-total td, .woocommerce-checkout .cart-collaterals .order-total td, .woocommerce-checkout .col-last .order-total td {
      font-size: 1.3888888889em; }

.woocommerce-cart .coupon, .woocommerce-checkout .coupon {
  width: 100%;
  float: left;
  margin-bottom: 2em; }
  .woocommerce-cart .coupon h3, .woocommerce-checkout .coupon h3 {
    font-size: 1.05em;
    padding: .5em 0;
    border-bottom: 2px solid #DDDDDD;
    margin: 0.5em 0; }
  .woocommerce-cart .coupon input, .woocommerce-checkout .coupon input {
    width: 60%;
    font-size: 0.8333333333em;
    border: 1px solid #888888;
    padding: 0 1em;
    border-radius: 5px;
    line-height: 2.3em;
    height: 2.4em; }
  .woocommerce-cart .coupon button, .woocommerce-checkout .coupon button {
    margin-left: 4%;
    font-size: 0.8333333333em;
    width: 35%;
    border: 0;
    color: #fff;
    background: url(../images/button-grey.svg) center center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    padding: 0.8em;
    cursor: pointer; }

.woocommerce-cart a.checkout-button, .woocommerce-checkout a.checkout-button {
  width: 100%;
  float: left;
  overflow: visible;
  position: relative;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 1.1111111111em;
  text-transform: uppercase;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  padding: 1.5em 0;
  text-decoration: none; }
  .woocommerce-cart a.checkout-button span, .woocommerce-checkout a.checkout-button span {
    z-index: 50;
    position: relative; }

table.shop_table {
  width: 100%;
  color: #00688D;
  border-top: none;
  border-left: none; }
  table.shop_table .product-remove a {
    color: #fff;
    background: url(../images/button-small.svg) center center no-repeat;
    background-size: 100% 100%;
    padding: 0.1em 0.4em;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    display: inline-block; }
  table.shop_table .product-name {
    width: 50%;
    line-height: 1.4em; }
  table.shop_table .product-price {
    text-align: center; }
  table.shop_table .product-quantity {
    text-align: center; }
  table.shop_table th {
    background: transparent;
    color: #00688D;
    border-bottom: 2px solid #DDDDDD; }
  table.shop_table td {
    border-right: none;
    border-bottom-color: #DDDDDD;
    font-size: 0.7777777778em;
    color: #17A1D3;
    padding: 1em 0.5em; }
    table.shop_table td a {
      color: #17A1D3;
      text-decoration: none;
      line-height: 1.4em; }
  table.shop_table input {
    border: 1px solid #17A1D3;
    color: #17A1D3;
    text-align: center;
    border-radius: 5px;
    width: 3.8em;
    line-height: 2.3em;
    height: 2.4em;
    font-size: 1em; }
  table.shop_table input::-webkit-inner-spin-button,
  table.shop_table input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

#customer_details h3 {
  font-size: 1.04em;
  padding: .5em 0;
  color: #00688D;
  border-bottom: 2px solid #DDDDDD;
  margin-bottom: 0.5em; }

#customer_details .woocommerce-billing-fields, #customer_details .woocommerce-shipping-fields {
  margin: 0 -1.2777777778em; }
  #customer_details .woocommerce-billing-fields h3, #customer_details .woocommerce-shipping-fields h3 {
    width: 100%;
    float: left;
    padding: 0 1.0765em;
    border-bottom: none; }
  #customer_details .woocommerce-billing-fields .create-account, #customer_details .woocommerce-shipping-fields .create-account {
    width: 100%;
    float: left; }
    #customer_details .woocommerce-billing-fields .create-account label, #customer_details .woocommerce-shipping-fields .create-account label {
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

#customer_details h3#ship-to-different-address {
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: .7777777778em;
  padding: 0 1.715em; }
  #customer_details h3#ship-to-different-address label {
    display: inline-block;
    margin-left: 0.2em; }
  #customer_details h3#ship-to-different-address input {
    float: left; }
  #customer_details h3#ship-to-different-address:after {
    content: '';
    display: inline-block;
    width: 100%;
    margin-top: 1em;
    border-bottom: 1px solid #DDDDDD; }

#customer_details .select2-container .select2-selection--single {
  height: 2.5em; }

#customer_details .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.45em;
  border-left: 1px solid #17A1D3; }

#customer_details .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.5em; }

#customer_details .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #17A1D3; }

#customer_details .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #17A1D3 transparent transparent transparent; }

#customer_details .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #17A1D3 transparent;
  border-width: 0 4px 5px 4px; }

#customer_details .select2-container--default .select2-selection--single {
  border-color: #17A1D3; }

#customer_details .create-account p {
  width: 100%;
  float: left;
  padding: 0 1.715em;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }

h3.checkout_heading {
  font-size: 1.04em;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  padding: .5em 0;
  color: #00688D;
  border-bottom: 2px solid #DDDDDD; }

#order_review .totals-outer {
  padding-top: 0.5em;
  padding-bottom: 0; }

#order_review ul.payment_methods {
  width: 100%;
  float: left;
  text-align: center;
  background: url(../images/cart-background.svg) center center no-repeat;
  background-size: 100% 100%;
  padding: 1em 0 0;
  margin-bottom: 1em;
  list-style: none;
  margin: 0 0 3em; }
  #order_review ul.payment_methods li {
    list-style: none;
    margin: 0;
    padding: 0; }
    #order_review ul.payment_methods li img {
      display: none; }

#order_review table .shipping th {
  vertical-align: top;
  padding-top: 1em; }

#order_review button {
  width: 100%;
  float: left;
  border: none;
  background: none;
  overflow: visible;
  position: relative;
  width: 100%;
  color: #fff;
  text-align: center;
  font-size: 1.1111111111em;
  text-transform: uppercase;
  font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
  padding: 1.5em 0;
  text-decoration: none; }
  #order_review button span {
    z-index: 50;
    position: relative; }

.woocommerce-checkout .thankyou {
  padding: 0 1.9em; }

.woocommerce-checkout .complete {
  width: 100%;
  float: left;
  padding: 0 5.5555555556em;
  margin-bottom: 4em; }
  .woocommerce-checkout .complete .message {
    font-size: 1.2em;
    margin-bottom: 0.5em; }
  .woocommerce-checkout .complete h2 {
    margin: 0.5em 0 0.5em; }
  .woocommerce-checkout .complete table.shop_table {
    margin-bottom: 3em; }
  .woocommerce-checkout .complete header {
    padding: 0;
    text-align: left; }
  .woocommerce-checkout .complete .product-total {
    text-align: right; }
  .woocommerce-checkout .complete tfoot th {
    border-bottom: 1px solid #DDD !important; }
  .woocommerce-checkout .complete tfoot td {
    text-align: right; }
  .woocommerce-checkout .complete table.customer_details th {
    border-bottom: 1px solid #DDD !important; }
  .woocommerce-checkout .complete table.customer_details td {
    text-align: right; }
  .woocommerce-checkout .complete .col2-set {
    width: 100%;
    float: left;
    padding: 0; }
    .woocommerce-checkout .complete .col2-set .col-1, .woocommerce-checkout .complete .col2-set .col-2 {
      width: 50%;
      float: left; }

.content-single ul.order_details {
  list-style: none;
  padding: 0;
  display: inline-block;
  background: url(../images/list.svg) center center no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 1.5em 1.5em 1em 1em; }
  .content-single ul.order_details li {
    display: inline-block;
    padding: 0 0.5555555556em; }
    .content-single ul.order_details li strong {
      font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif; }
      .content-single ul.order_details li strong:before {
        content: '';
        display: table; }

table.shop_table.order_details .product-total {
  text-align: right; }

table.shop_table.order_details tfoot th {
  border-bottom: 1px solid #DDD;
  border-right: 1px solid #DDD;
  width: 80%;
  text-align: right; }

table.shop_table.order_details tfoot td {
  text-align: right;
  width: 20%; }

table.shop_table.customer_details th {
  border-bottom: 1px solid #DDD;
  width: 20%;
  text-align: left; }

table.shop_table.customer_details td {
  text-align: left;
  width: 80%; }

/**********************************************************************

Footer

**********************************************************************/
#newsletter {
  text-align: center;
  margin-bottom: 2.7777777778em;
  position: relative; }
  #newsletter .layout:before {
    content: '';
    width: 100%;
    float: left;
    margin-bottom: 2.2222222222em;
    height: 1px;
    background: #7CBCD2; }
  #newsletter h3 {
    display: inline-block;
    color: #06A8DC;
    margin-bottom: 0.5em;
    margin-right: 1em;
    font-family: "aileronbold",Arial,"Helvetica Neue",Helvetica,sans-serif; }
  #newsletter .gfield_label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px; }
  #newsletter .gform_wrapper, #newsletter .gform_body, #newsletter .ginput_container, #newsletter .gform_footer, #newsletter ul, #newsletter li {
    display: inline-block; }
  #newsletter .ginput_container input {
    background: url("../images/input-bg.svg") center center no-repeat;
    background-size: 100% 100%;
    color: #fff;
    border: 0;
    font-size: 0.8333333333em;
    line-height: 0.9em;
    height: 2.8em;
    padding: 0 0.6666666667em;
    width: 18.6666666667em;
    font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin-right: 0.6666666667em;
    text-align: left; }
    #newsletter .ginput_container input::placeholder {
      color: #fff;
      font-family: "aileronlight", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 1em;
      line-height: 1em;
      opacity: 1;
      margin: 0; }
    #newsletter .ginput_container input::-moz-placeholder {
      line-height: 2.8em; }
  #newsletter button {
    background: url("../images/button-form-submit.svg") center center no-repeat;
    color: #fff;
    border: 0;
    font-family: "aileronbold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 0.7222222222em;
    line-height: 1em;
    height: 3.2307692308em;
    padding: 0 1.5384615385em; }
  #newsletter .validation_error {
    position: absolute;
    top: 0.5em;
    left: 0;
    right: 0;
    color: red;
    font-size: 0.8333333333em; }
  #newsletter .validation_message {
    position: absolute;
    bottom: -2.5em;
    left: 0;
    right: 0;
    color: red;
    font-size: 0.8333333333em; }

footer[role=contentinfo] {
  background: url("../images/footer-background.svg") top center no-repeat;
  background-size: cover;
  min-height: 8.3333333333em;
  padding: 4.4444444444em 0 2.7777777778em;
  color: #fff; }
  footer[role=contentinfo] a {
    color: #fff;
    text-decoration: underline; }
  footer[role=contentinfo] h4 {
    font-size: 0.8333333333em;
    font-family: "aileronregular", Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin-bottom: 1em;
    position: relative; }
  footer[role=contentinfo] h3 {
    font-size: 1.6666666667em;
    position: relative; }
    footer[role=contentinfo] h3 a {
      text-decoration: none; }
  footer[role=contentinfo] li {
    font-size: 0.7222222222em;
    line-height: 2em; }
  footer[role=contentinfo] img.band {
    position: absolute;
    top: -3em;
    right: 7em;
    width: 100%; }
  footer[role=contentinfo] img.notes_1 {
    position: absolute;
    top: -2.5em;
    left: 6em;
    width: 3.3333333333em;
    height: auto; }
  footer[role=contentinfo] img.notes_2 {
    position: absolute;
    top: -3em;
    right: 8.5em;
    width: 3em;
    height: auto; }
  footer[role=contentinfo] .contact-info {
    width: 35.7142857143%;
    float: left;
    position: relative; }
  footer[role=contentinfo] .social-info {
    width: 35.7142857143%;
    float: left;
    position: relative; }
    footer[role=contentinfo] .social-info .links {
      width: 100%;
      float: left;
      margin-top: 1em; }
      footer[role=contentinfo] .social-info .links h4 {
        display: inline-block;
        margin-right: .2em; }
      footer[role=contentinfo] .social-info .links a {
        display: inline-block;
        text-decoration: none;
        vertical-align: -.2em; }
  footer[role=contentinfo] .the_tweet {
    font-size: .7222222222em;
    line-height: 1.5em; }
  footer[role=contentinfo] .info-links {
    width: 14.2857142857%;
    float: left; }
  footer[role=contentinfo] .legal-links {
    width: 14.2857142857%;
    float: left;
    position: relative; }
    footer[role=contentinfo] .legal-links img.notes {
      top: -3.5em;
      left: 2em; }
  footer[role=contentinfo] .security-info {
    width: 14.2857142857%;
    float: left; }
    footer[role=contentinfo] .security-info h4 {
      text-align: right; }
    footer[role=contentinfo] .security-info img {
      max-width: 100%;
      height: auto !important; }
  footer[role=contentinfo] .payment {
    float: right;
    margin-right: -0.8em; }
  footer[role=contentinfo] .site-by {
    margin-top: 1.5em;
    width: 100%;
    float: left;
    font-size: .7222222222em; }

/**********************************************************************

Off screen menus

**********************************************************************/
html.nav-open {
  background: url(../images/ricepaper2.png) center center repeat; }
  html.nav-open body {
    background: url(../images/ricepaper2.png) center center repeat; }
  html.nav-open a.off-page-return {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(38, 165, 207, 0.8);
    opacity: 0;
    box-shadow: -4px 0 4px rgba(0, 0, 0, 0.2), 4px 0 4px rgba(0, 0, 0, 0.2); }

.right-off-page-nav {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(19em);
  width: 19em;
  color: #fff;
  display: none;
  background: url(../images/ricepaper2.png) center center repeat; }
  .nav-open .right-off-page-nav {
    display: block; }
  .right-off-page-nav .search-form {
    width: 100%;
    float: left;
    padding: 1em; }
    .right-off-page-nav .search-form input {
      border: none;
      color: #FFC000;
      background: #fff;
      font-size: 1.5em;
      height: 1.8em;
      line-height: 1em;
      width: 85%;
      float: left;
      position: relative; }
      .right-off-page-nav .search-form input::placeholder {
        color: #FFC000;
        line-height: 1.2em;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        vertical-align: middle;
        opacity: 1; }
    .right-off-page-nav .search-form button {
      border: none;
      color: #FFC000;
      background: #fff;
      font-size: 1.5em;
      height: 1.8em;
      width: 15%;
      float: right;
      text-align: center;
      padding: 0; }
  .right-off-page-nav ul {
    width: 100%;
    float: left;
    font-size: 1.5em; }
    .right-off-page-nav ul li {
      width: 100%;
      float: left; }
    .right-off-page-nav ul a {
      color: #fff;
      display: block;
      text-decoration: none;
      padding: 0.5em 1em;
      border-bottom: 1px solid #6ACBEA; }

/**********************************************************************

Responsive

**********************************************************************/
/* @media screen and (max-width: 850px) */
@media screen and (max-width: 850px) {
  html {
    font-size: 15px; }
  .content-single .gform_body li.left, .content-single .gform_body li.right {
    width: 100%; }
  .content-single .gform_body .ginput_complex span.address_line_1, .content-single .gform_body .ginput_complex span.address_city {
    width: 100%; }
  #home_header .intro_image {
    margin-top: 2em;
    width: 50%; }
  #home_header .intro {
    width: 50%; }
  #frontpage_buttons li {
    width: 50%;
    margin-bottom: 2em; }
    .js #frontpage_buttons li {
      opacity: 0; }
    #frontpage_buttons li h3 {
      padding-top: 6.5em; }
    #frontpage_buttons li h4 {
      padding-top: 0.5em;
      margin-bottom: 0.5em; }
    #frontpage_buttons li #featured img {
      padding-top: 4em; }
  #homepage_shortcuts .attributes {
    width: 100%;
    margin-bottom: 2.7777777778em; }
  #homepage_shortcuts .category {
    width: 100%;
    padding: 0 0.6666666667em; }
    #homepage_shortcuts .category a.title {
      width: 80%; }
    #homepage_shortcuts .category div.actions {
      width: 20%; }
  .about-us .intro-content {
    margin: 0 2.7777777778em 2em; }
  article.content-single .image-left-content .table, article.content-single .image-right-content .table {
    height: 60%; }
  .content-single .contact-form {
    padding: 2em 0.5em; }
  .content-single .series-list {
    width: 50%; }
    .content-single .series-list:nth-of-type(3n+4) {
      clear: none; }
    .content-single .series-list:nth-of-type(2n+3) {
      clear: both; }
  ul.products.grid li {
    width: 50%; }
    ul.products.grid li .options {
      padding-top: 1.1111111111em;
      width: 100%;
      float: left;
      position: relative; }
      ul.products.grid li .options .table {
        background: 0 0;
        position: relative;
        opacity: 1; }
      ul.products.grid li .options .form {
        position: relative; }
  ul.products.list li h3 {
    padding-right: 4em; }
  ul.products.list li .price {
    margin-top: -2.5em; }
  ul.products.list li .options {
    padding-top: 1.1111111111em;
    width: 100%;
    float: left;
    position: relative; }
    ul.products.list li .options .table {
      background: 0 0;
      position: relative;
      opacity: 1; }
  .woocommerce-account .content-single {
    padding: 2em 1.555556em 0; }
  .woocommerce-cart .basket {
    width: 50%; }
  .woocommerce-cart .cart-collaterals {
    width: 50%; }
  .woocommerce-checkout .col2-set {
    width: 50%; }
  .woocommerce-checkout .col-last {
    width: 50%; }
  .woocommerce-checkout .thankyou {
    padding: 0 0.43333em; }
  .woocommerce-checkout .complete {
    width: 100%;
    float: left;
    padding: 0 1.85em; }
  footer[role=contentinfo] .contact-info {
    width: 50%;
    margin-bottom: 5em; }
  footer[role=contentinfo] .info-links {
    width: 50%; }
  footer[role=contentinfo] img.band {
    right: -11em; }
  footer[role=contentinfo] .social-info {
    width: 50%;
    clear: both; }
  footer[role=contentinfo] .security-info {
    width: 50%; }
  footer[role=contentinfo] .site-by {
    margin-top: 3em; } }

/* @media screen and (max-width: 414px) */
@media screen and (max-width: 414px) {
  html {
    font-size: 14px; }
  p.form-row.form-row-first {
    width: 100%;
    padding-right: 0.0; }
  p.form-row.form-row-last {
    width: 100%;
    padding-left: 0; }
  header.banner a.brand {
    padding-top: 3em;
    width: 50%;
    margin-left: 1.3em; }
    .woocommerce-checkout header.banner a.brand {
      padding-top: 0; }
  header.banner a.nav-toggle {
    display: inline;
    float: right;
    font-size: 3em;
    margin-top: 1.8em;
    padding: .2em;
    color: #FFBF00; }
    header.banner a.nav-toggle a {
      color: #FFBF00; }
    header.banner a.nav-toggle:after {
      content: 'Menu';
      font-size: .5em;
      vertical-align: .5em; }
  header.banner .site-header-store-navigation {
    position: absolute;
    top: 0;
    right: 2em;
    left: 2em;
    text-align: center; }
  header.banner .security-info img {
    width: 8em; }
  #site-navigation {
    display: none; }
  #breadcrumb {
    padding-top: 4em;
    text-align: center;
    margin-bottom: 0; }
    #breadcrumb #social-share, #breadcrumb .breadcrumbs {
      display: inline-block;
      width: 100%;
      float: none;
      font-size: 1.2em;
      margin-bottom: 1em; }
      #breadcrumb #social-share div, #breadcrumb #social-share p, #breadcrumb #social-share a, #breadcrumb .breadcrumbs div, #breadcrumb .breadcrumbs p, #breadcrumb .breadcrumbs a {
        float: none;
        display: inline-block;
        vertical-align: -0.2em; }
  .intro-row {
    padding: 2em 2em 0; }
  #home_header {
    margin-top: 2em;
    text-align: center; }
    #home_header .intro_image {
      margin-top: -1em;
      width: 100%;
      padding-bottom: 46%; }
    #home_header .intro {
      margin-top: -2em;
      width: 100%; }
  #frontpage_buttons {
    margin-bottom: 10em; }
    #frontpage_buttons li {
      width: 100%;
      padding: 0 5em;
      margin-bottom: 2em; }
      #frontpage_buttons li h3 {
        padding-top: 3.75em; }
      #frontpage_buttons li #featured img {
        padding-top: 2.5em; }
  #homepage_shortcuts .attribute {
    width: 100%;
    margin-bottom: 3em; }
  #homepage_shortcuts .category a.title {
    float: left;
    width: 100%;
    padding: 1em 0 0.5em;
    height: auto; }
  #homepage_shortcuts .category div.actions {
    float: left;
    width: 100%;
    padding: 0.5em 0;
    height: auto; }
  .articles article {
    width: 100%; }
  article.content-single header {
    padding: 0 2em; }
  article.content-single .image-left-content, article.content-single .image-right-content {
    padding: 0 1.5em; }
    article.content-single .image-left-content .image, article.content-single .image-right-content .image {
      width: 100%;
      margin-top: 0.75em;
      margin-bottom: 0.75em; }
    article.content-single .image-left-content .content, article.content-single .image-right-content .content {
      width: 100%;
      margin-top: 0.75em;
      margin-bottom: 0.75em; }
    article.content-single .image-left-content .table, article.content-single .image-right-content .table {
      height: 100%; }
  article.content-single .divide {
    margin: 0 3em 2em; }
  article.content-single .media {
    margin: 3.8888888889em 1.5em; }
  .content-single .contact-form .form-content {
    width: 100%;
    padding: 0 1.5em; }
  .content-single .contact-form .form {
    width: 100%;
    padding: 0 1.5em; }
  .profile.row .image-outer {
    width: 100%;
    float: left;
    padding: 0 0 80%;
    margin-bottom: 2em; }
  .profile.row .content {
    width: 100%;
    float: left; }
  .content-single .series-list {
    width: 100%; }
    .content-single .series-list:nth-of-type(2n+3) {
      clear: none; }
  .header-intro .trumpet.left {
    left: -5em; }
  .header-intro .trumpet.right {
    right: -4em; }
  .header-intro .sax.left {
    left: -3em; }
  .header-intro .sax.right {
    right: -4em; }
  .header-intro .drum.left {
    left: -5em; }
  .header-intro .drum.right {
    right: -3em; }
  .header-intro .bass.left {
    left: -4em; }
  .header-intro .bass.right {
    right: -4em; }
  .header-intro .flute.left {
    left: -4em; }
  .header-intro .flute.right {
    right: -4em; }
  .header-intro .notes.left {
    left: -7em; }
  .header-intro .notes.right {
    right: -8em; }
  .header-intro .trombone.left {
    left: -4em; }
  .header-intro .trombone.right {
    right: -4em; }
  .header-intro .wrapper {
    padding: 0 3.5em 2em;
    position: relative; }
  #filters {
    display: none; }
  #product-list {
    width: 100%; }
  .controls .woocommerce-result-count {
    float: none;
    margin-bottom: 1.2em; }
  .controls .woocommerce-pagination {
    font-size: 1.5em;
    margin-top: 1em;
    text-align: center;
    width: 100%; }
    .controls .woocommerce-pagination a {
      margin-left: 0.3em; }
  .controls .gridlist-toggle {
    font-size: 1.5em; }
  .single-product .summary {
    width: 100%; }
  .single-product .sidebar {
    width: 100%; }
  .single-product .shop_attributes {
    margin-bottom: 2em; }
  .single-product .add-to-cart {
    margin-bottom: 2em; }
  #newsletter h3 {
    margin-bottom: 1em;
    margin-right: 0; }
  .woocommerce-cart .basket {
    width: 100%; }
  .woocommerce-cart .cart-collaterals {
    width: 100%; }
  .woocommerce-account .my_account_orders thead {
    display: none; }
  .woocommerce-account .my_account_orders tr {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #DDD;
    padding: 0.5em 0; }
  .woocommerce-account .my_account_orders td {
    display: inline-block;
    width: 100%;
    border-bottom-color: #fff;
    padding: 0;
    word-spacing: normal; }
    .woocommerce-account .my_account_orders td.order-number {
      width: 49%;
      text-align: left; }
    .woocommerce-account .my_account_orders td.order-date {
      width: 49%;
      text-align: right; }
    .woocommerce-account .my_account_orders td.order-status {
      width: 49%;
      text-align: left; }
    .woocommerce-account .my_account_orders td.order-total {
      width: 49%;
      text-align: right; }
    .woocommerce-account .my_account_orders td.order-actions {
      padding-top: 1em; }
  .woocommerce-account .order-summary .col2-set .col-1, .woocommerce-account .order-summary .col2-set .col-2 {
    width: 100%;
    margin-bottom: 2em; }
    .woocommerce-account .order-summary .col2-set .col-1 address, .woocommerce-account .order-summary .col2-set .col-2 address {
      line-height: 1.5em; }
  .woocommerce-account .col-1.address, .woocommerce-account .col-2.address {
    width: 100%; }
  .woocommerce-account .col2-set.addresses a {
    float: right;
    line-height: 1.2em;
    margin-top: 0.2em;
    padding: 1em 1em 0.8em; }
  .woocommerce-checkout .col2-set {
    width: 100%; }
  .woocommerce-checkout .col-last {
    width: 100%; }
  .woocommerce-checkout #customer_details .woocommerce-billing-fields p.form-row#billing_country_field, .woocommerce-checkout #customer_details .woocommerce-billing-fields p.form-row#shipping_country_field, .woocommerce-checkout #customer_details .woocommerce-shipping-fields p.form-row#billing_country_field, .woocommerce-checkout #customer_details .woocommerce-shipping-fields p.form-row#shipping_country_field,
  .woocommerce-checkout #customer_details .woocommerce-billing-fields p.form-row.form-row-first, .woocommerce-checkout #customer_details .woocommerce-shipping-fields p.form-row.form-row-first,
  .woocommerce-checkout #customer_details .woocommerce-billing-fields p.form-row.form-row-last, .woocommerce-checkout #customer_details .woocommerce-shipping-fields p.form-row.form-row-last {
    width: 100%; }
  .woocommerce-checkout #customer_details .woocommerce-billing-fields label, .woocommerce-checkout #customer_details .woocommerce-shipping-fields label {
    font-size: 1.5em; }
  .woocommerce-checkout #customer_details .woocommerce-billing-fields input[type=password], .woocommerce-checkout #customer_details .woocommerce-billing-fields input[type=text], .woocommerce-checkout #customer_details .woocommerce-shipping-fields input[type=password], .woocommerce-checkout #customer_details .woocommerce-shipping-fields input[type=text] {
    font-size: 1.5em; }
  .woocommerce-checkout .select2-container {
    font-size: 1.5em; }
  .woocommerce-checkout #customer_details .woocommerce-billing-fields textarea, .woocommerce-checkout #customer_details .woocommerce-shipping-fields textarea {
    font-size: 1.5em; }
  .woocommerce-checkout #customer_details h3 {
    font-size: 1.5em; }
  .woocommerce-cart .cart-collaterals .woocommerce-checkout-review-order-table, .woocommerce-cart .col-last .woocommerce-checkout-review-order-table, .woocommerce-checkout .cart-collaterals .woocommerce-checkout-review-order-table, .woocommerce-checkout .col-last .woocommerce-checkout-review-order-table {
    font-size: 1em; }
  .woocommerce-checkout .col-last table .cart_item td, .woocommerce-checkout .col-last table th {
    font-size: 1.2em;
    padding: 1em 1.5em;
    width: 50%; }
  .woocommerce-checkout table.shop_table .shipping td {
    font-size: 0.8em; }
  .woocommerce-checkout table.woocommerce-checkout-review-order-table tfoot th {
    vertical-align: middle; }
  .content-single ul.order_details li {
    width: 100%; }
  .content-single .col2-set.addresses h3 {
    width: 50%; }
  .woocommerce-checkout .complete .col2-set .col-1, .woocommerce-checkout .complete .col2-set .col-2 {
    width: 100%; }
  footer[role=contentinfo] .contact-info {
    width: 100%;
    margin-bottom: 5em; }
  footer[role=contentinfo] .info-links {
    width: 100%; }
  footer[role=contentinfo] img.band {
    right: -1em;
    top: -9em; }
  footer[role=contentinfo] .social-info {
    width: 100%; }
  footer[role=contentinfo] .security-info {
    width: 100%;
    text-align: center; }
  footer[role=contentinfo] .site-by {
    margin-top: 3em; } }

/* @media screen and (max-width: 360px) */
@media screen and (max-width: 360px) {
  html {
    font-size: 12px; }
  header.banner .site-header-store-navigation {
    right: 0;
    left: 0; }
  .intro-row {
    padding: 0 0.5em; }
  #frontpage_buttons {
    margin-bottom: 8em; }
    #frontpage_buttons li {
      padding: 0 3em;
      margin-bottom: 3.5em; }
  .header-intro .wrapper {
    padding: 0 1.5em 2em; }
  ul.products.grid li {
    width: 100%; } }

@media screen and (max-width: 1280px) {
  #frontpage_buttons li h3 {
    padding-top: 3.75em; } }

@media screen and (max-width: 850px) {
  #frontpage_buttons li h3 {
    padding-top: 6.5em; } }

@media screen and (max-width: 414px) {
  #frontpage_buttons li h3 {
    padding-top: 3.75em; } }
